@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 100px;
    height: 100px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #17d1bd;
    /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    
}

.spinner-container {
    margin-top: 10%;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
}