@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');
body {
    font-family: 'Poppins';
    color: #444444;

  }
  
  a {
    color: #03cae4;
    text-decoration: none;
  }
  
  a:hover {
    color: #3b8af2;
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Roboto", sans-serif;
  }

  #header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    height: 86px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #header.fixed-top {
    height: 70px;
  }
  
   .logo {
    font-size: 30px;
    max-width: 200px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.8px;
    font-family: "Poppins", sans-serif;
    color: #222222;
  }
  
  #header .logo a {
    color: #222222;
  }
  
.logo span {
    color: #03cae4;
  }
  
  #header .logo img {
    max-height: 40px;
  }
  
  .scrolled-offset {
    margin-top: 70px;
  }
  .navbar {
  padding: 0;
}
.navbar a{
  gap: 10px;
}


.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 15px;
  font-weight: 600;
  color: #222222;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}
.navbar>ul>li>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #03cae4;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
}

.navbar a:hover,
.navbar .active:focus,
.navbar li:hover>a {
  color: #03cae4;
}
.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link{
  color: #03cae4 !important;
}
.navbar-toggler{
  border: none !important;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  color: #03cae4;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}.label{
  font-size: 100px;;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.navbar-toggler:focus {
  text-decoration: none;
  background: none !important;
  outline: none !important;
  border: none !important;
}




@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

#hero .btn-get-started:hover {
  background: #247cf0;
}





@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    height: 100vh;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .btn-get-started {
    font-size: 13px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}


#hero {
    width: 100%;
    height: 75vh;
    background: url("../img/kqs.PNG") top left;
    background-size: cover;
    position: relative;
  }
  
  #hero:before {
    content: "";
    /*background: rgba(255, 255, 255, 0.6);*/
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero .container {
    position: relative;
  }
  
  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #222222;
    font-family: "Poppins", sans-serif;
  }
  
  #hero h1 span {
    color: #106eea;
  }
  
  #hero h2 {
    color: #555555;
    margin: 5px 0 30px 0;
    font-size: 24px;
    font-weight: 400;
  }
  
  #hero .btn-get-started {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #03cae4;
    text-decoration: none;
  }
  
  #hero .btn-get-started:hover {
    background: #247cf0;
  }

  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 768px) {
    #hero {
      height: 100vh;
    }
  
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  
    #hero .btn-get-started,
    #hero .btn-watch-video {
      font-size: 13px;
    }
  }
  
  @media (max-height: 500px) {
    #hero {
      height: 120vh;
    }
  }
  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 35px;
  }
  
  .about .content ul li:first-child {
    margin-top: 35px;
  }
  
  .about .content ul i {
    background: #fff;
    box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
    font-size: 24px;
    padding: 20px;
    margin-right: 15px;
    color: #03cae4;
    border-radius: 50px;
  }
  
  .about .content ul h5 {
    font-size: 18px;
    color: #555555;
  }
  
  .about .content ul p {
    font-size: 15px;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    /*background-color: #f6f9fe;*/
    background-image: linear-gradient(#f6f9fe, #fff);
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
.section-admin{
text-align: right;
  }
.section-admin h2 {
      float: left;
   }
   .section-button{
    float: right;
   }
     .section-button button{
       border-radius: 0px 0px 0px 20px;
     }
  .scrollto{
    text-decoration: none;
    /*color: #03cae4 !important;*/
  }
  .back{
    float: left;
    color: #03cae4;
    height: 20px;

  }
.back button{
  background: none;
  color: #03cae4;
  border-radius: 0px 0px 20px 0px;
}
.back button:hover{
  color: #ffffff;
}
  
  .section-title h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
    background: #e7f1fd;
    color: #03cae4;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
  }
  
  .section-title h3 {
    margin: 15px 0 0 0;
    font-size: 32px;
    font-weight: 700;
  }
  
  .section-title h3 span {
    color: #03cae4;
  }
  
  .section-title p {
    margin: 15px auto 0 auto;
    font-weight: 600;
  }

  .ftco-section {
    padding: 7em 0; }
  
  .ftco-no-pt {
    padding-top: 0; }
  
  .ftco-no-pb {
    padding-bottom: 0; }
  
  .heading-section {
    font-size: 28px;
    color: #000; }
  
  .img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; 
    background-image: url(../img/kqs.PNG);
   }
  
  .wrap {
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24); }
  
  .img, .login-wrap {
    width: 100%; }
    @media (max-width: 991.98px) {
      .img, .login-wrap {
        width: 100%; } }
  
  @media (max-width: 767.98px) {
    .wrap .img {
      height: 250px; } }
  
  .login-wrap {
    position: relative;
    background: #fff h3;
    background-font-weight: 300; }
  
  .form-group {
    position: relative; }
    .form-group .label {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #000;
      font-weight: 700; }
    .form-group a {
      color: gray; }
  
  .form-control {
    height: 48px;
    background: #fff;
    color: #000;
    font-size: 16px;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    .form-control::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(0, 0, 0, 0.2) !important; }
    .form-control::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(0, 0, 0, 0.2) !important; }
    .form-control:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(0, 0, 0, 0.2) !important; }
    .form-control:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(0, 0, 0, 0.2) !important; }
    .form-control:focus, .form-control:active {
      outline: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 1px solid #03cae4; } 

  
  .btn {
    cursor: pointer;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 15px;
    padding: 10px 20px; }
.btn:hover, .btn:active, .btn:focus {
      outline: none; }
.btn.btn-primary {
      background: #03cae4 !important;
      border: 1px solid #03cae4 !important;
      color: #fff !important; }
.btn.btn-primary:hover {
        border: 1px solid #03cae4;
        background: transparent;
        color: #03cae4; }
.btn.btn-primary.btn-outline-primary {
        border: 1px solid #e3b04b;
        background: transparent;
        color: #03cae4; }
.btn.btn-primary.btn-outline-primary:hover {
          border: 1px solid transparent;
          background: #03cae4;
          color: #fff; }

.quiz-container{
  /*background-color: #333;*/
  background-image: url(../img/water.png);
  background-blend-mode: multiply;
  border-radius: 10px;
  box-shadow: 0 0 10px 2px rgba(100, 100, 100, 0.1);
  overflow: hidden;
}
.quiz-header{
  padding: 4rem;
}
h2{
  padding: 1rem;
  text-align: center;
  margin: 0;
}
button{
  background-color: #03cae4;
  color: #fff;
  border: none;
  display: block;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: inherit;
  padding: 1.3rem;
}
button:hover{
  background-color: #04adc4;
}
.card{
  border:none !important;
}
.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text {
  color: #aaa;
}

.value {
  font-size: 30px;
}

.card-margin {
  margin-bottom: 1.875rem;
}

.card {
  border: 0;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #e6e4e9;
  border-radius: 8px;
}

.card .card-header.no-border {
  border: 0;
}
.card .card-header {
  background: none;
  padding: 0 0.9375rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  min-height: 50px;
}
.card-header:first-child {
  border-radius: calc(8px - 1px) calc(8px - 1px) 0 0;
}

.widget-49 .widget-49-title-wrapper {
display: flex;
align-items: center;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
/*background-color: #edf1fc;*/
width: 4rem;
height: 4rem;
border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary .widget-49-date-day {
color: #4e73e5;
font-weight: 500;
font-size: 1.5rem;
line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary .widget-49-date-month {
color: #4e73e5;
line-height: 1;
font-size: 1rem;
text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-secondary {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #fcfcfd;
width: 4rem;
height: 4rem;
border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-secondary .widget-49-date-day {
color: #dde1e9;
font-weight: 500;
font-size: 1.5rem;
line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-secondary .widget-49-date-month {
color: #dde1e9;
line-height: 1;
font-size: 1rem;
text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-success {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #e8faf8;
width: 4rem;
height: 4rem;
border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-success .widget-49-date-day {
color: #17d1bd;
font-weight: 500;
font-size: 1.5rem;
line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-success .widget-49-date-month {
color: #17d1bd;
line-height: 1;
font-size: 1rem;
text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #ebf7ff;
width: 4rem;
height: 4rem;
border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info .widget-49-date-day {
color: #36afff;
font-weight: 500;
font-size: 1.5rem;
line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info .widget-49-date-month {
color: #36afff;
line-height: 1;
font-size: 1rem;
text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-warning {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: floralwhite;
width: 4rem;
height: 4rem;
border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-warning .widget-49-date-day {
color: #FFC868;
font-weight: 500;
font-size: 1.5rem;
line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-warning .widget-49-date-month {
color: #FFC868;
line-height: 1;
font-size: 1rem;
text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-danger {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #feeeef;
width: 4rem;
height: 4rem;
border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-danger .widget-49-date-day {
color: #F95062;
font-weight: 500;
font-size: 1.5rem;
line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-danger .widget-49-date-month {
color: #F95062;
line-height: 1;
font-size: 1rem;
text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-light {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #fefeff;
width: 4rem;
height: 4rem;
border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-light .widget-49-date-day {
color: #f7f9fa;
font-weight: 500;
font-size: 1.5rem;
line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-light .widget-49-date-month {
color: #f7f9fa;
line-height: 1;
font-size: 1rem;
text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-dark {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #ebedee;
width: 4rem;
height: 4rem;
border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-dark .widget-49-date-day {
color: #394856;
font-weight: 500;
font-size: 1.5rem;
line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-dark .widget-49-date-month {
color: #394856;
line-height: 1;
font-size: 1rem;
text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
/*background-color: #f0fafb;*/
width: 4rem;
height: 4rem;
border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base .widget-49-date-day {
color: #68CBD7;
font-weight: 500;
font-size: 1.5rem;
line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base .widget-49-date-month {
color: #68CBD7;
line-height: 1;
font-size: 1rem;
text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info {
display: flex;
flex-direction: column;
margin-left: 1rem;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info .widget-49-pro-title {
color: #3c4142;
font-size: 14px;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info .widget-49-meeting-time {
color: #B1BAC5;
font-size: 13px;
}

.widget-49 .widget-49-meeting-points {
font-weight: 400;
font-size: 13px;
margin-top: .5rem;
}

.widget-49 .widget-49-meeting-points .widget-49-meeting-item {
display: list-item;
color: #727686;
}

.widget-49 .widget-49-meeting-points .widget-49-meeting-item span {
margin-left: .5rem;
}

.widget-49 .widget-49-meeting-action {
text-align: right;
}

.widget-49 .widget-49-meeting-action a {
text-transform: uppercase;
}
#submit{
  width:100% ;
}
 .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
}

.info-box i {
  font-size: 32px;
  color: #17d1bd;
  border-radius: 50%;
  padding: 8px;
}

.info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

 .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.copyright {
  text-align: center;
  float: left;
}

 .credits {
  float: right;
  text-align: center;
  font-size: 13px;
  color: #444444;
}
.watermark{
  width: 100px;
  height: 100px;
  float: left;
}


